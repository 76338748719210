import React, { useState, useEffect, useRef } from 'react'
import Amplify, { Auth, Hub } from 'aws-amplify'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Userpilot } from 'userpilot'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'

import { Layout, Sidebar, PrivateRoute } from 'components'
import { Loader } from 'components/UI'
import { Explorer, Credentials, Home } from 'pages'

import { awsConfig } from 'consts'
import 'styles/main.css'

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: awsConfig.USERPOOL_ID[process.env.REACT_APP_ENV],
    userPoolWebClientId: awsConfig.WEBCLIENT_ID[process.env.REACT_APP_ENV],
    cookieStorage: awsConfig.COOKIE_STORAGE[process.env.REACT_APP_ENV],
  },
})

Auth.configure({
  domain: awsConfig.AUTH_DOMAIN[process.env.REACT_APP_ENV],
  scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: awsConfig.REDIRECT_URI[process.env.REACT_APP_ENV],
  redirectSignOut: awsConfig.REDIRECT_URI[process.env.REACT_APP_ENV],
  responseType: 'code',
})

const queryCache = new QueryCache()

function App() {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const history = useHistory()
  const { pathname } = useLocation()
  const pathnameRef = useRef()

  useEffect(() => {
    Userpilot.initialize('62qe8i8')
  }, [])

  useEffect(() => {
    if (pathnameRef.current !== pathname) {
      Userpilot.reload()
    }

    pathnameRef.current = pathname
  }, [pathname])

  useEffect(() => {
    try {
      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'signIn':
            console.debug('[ ℹ ] (App.js:43):', data)
            setUser(data)
            break
          case 'signOut':
            Userpilot.anonymous()
            setUser(null)
            break
          default:
            break
        }
      })
    } catch (err) {
      console.error('Hub error :: ', err)
    }
  })

  async function authCheck() {
    try {
      setLoading(true)
      const authenticatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
      // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      const {
        email,
        family_name,
        given_name,
        expereoOneRole: role,
        accountName: company,
      } = authenticatedUser?.attributes

      Userpilot.identify(email, {
        name: `${given_name} ${family_name}`,
        email,
        role,
        company,
        created_at: Date.now(), // Signup date as a Unix timestamp
      })

      setUser(authenticatedUser)
    } catch (err) {
      // console.error('currentAuthenticatedUser error :: ', err)
      setUser(null)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!user) {
      authCheck()
    }
  }, [user])

  const handleLogout = async () => {
    await Auth.signOut()
    history.push('/')
  }

  if (loading) return <Loader className="h-screen">loading...</Loader>

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Layout className="background">
        <Sidebar user={user} onLogout={handleLogout} />
        <Switch>
          <PrivateRoute path="/apiexplorer" user={user}>
            <Explorer />
          </PrivateRoute>
          <PrivateRoute path="/credentials" user={user}>
            <Credentials />
          </PrivateRoute>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Layout>
      <ReactQueryDevtools />
    </ReactQueryCacheProvider>
  )
}

export default App
