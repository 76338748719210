import React from 'react'

import { Heading, Modal } from 'components/UI'
import { CREATE } from 'consts/modals'

const ClientCredentialsModal = ({ apiClientId, clientSecret, isVisible, onClose }) => (
  <Modal isVisible={isVisible} onClose={onClose} type={CREATE}>
    <div className="row">
      <Heading type="h4">Client ID:</Heading>
      <input name="api-client-id" readOnly value={apiClientId} />
    </div>
    <div className="row">
      <Heading type="h4">Client Secret:</Heading>
      <input name="client-secret" readOnly value={clientSecret} />
    </div>
    <p className="text-xs mt-2">
      Save this key in a secure location as it is only going to be shown once!
    </p>
  </Modal>
)

export default ClientCredentialsModal
