import React from 'react'

const Check = ({ disabled = false, checked = false }) => {
  const innerRectFill = disabled ? '#ACB5C4' : '#6DDD6B'

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="18" height="18" rx="2" stroke="#ACB5C4" strokeWidth="2" />
      {checked && (
        <rect id="inner-rect" x="4" y="4" width="12" height="12" rx="1" fill={innerRectFill} />
      )}
    </svg>
  )
}

export default Check
