/* eslint-disable no-unused-vars */
const templateExample = {
  title: '',
  summary: '',
  description: `# Markdown syntax goes here`,
  stringCodeRequest: `query Example goes here`,
  stringCodeResponse: `JSON response goes here`,
}

const customExamples = [
  {
    title: 'Retrieve services and sites',
    summary: 'How to retrieve services and sites',
    description: ``,
    stringCodeRequest: `query RetrieveServicesAndSites {
  viewer {
    account {
      accountId
      name
      services(id: "SERVICE_ID", serviceName: "SERVICE_NAME") {
        deliveryAccount
        plannedDeliveryDate
        primaryDns
        secondaryDns
        tertiaryDns
        readyForServiceDate
        requestedDeliveryDate
        serviceId
        serviceCategory
        serviceName
        technicalNote
      }
      sites(id: "SITE_ID", name: "SITE_NAME") {
        siteId
        name
        deliveryAccount
        city
        country
      }
    }
  }
}`,
    stringCodeResponse: `{
  "data": {
    "viewer": {
      "account": {
        "accountId": "string",
        "name": "string",
        "services": [
          {
            "deliveryAccount": "string",
            "plannedDeliveryDate": "string",
            "primaryDns": "string",
            "secondaryDns": "string",
            "tertiaryDns": "string",
            "readyForServiceDate": "string",
            "requestedDeliveryDate": "string",
            "serviceId": "string",
            "serviceCategory": "string",
            "serviceName": "string",
            "technicalNote": "string",
          },
          "sites": [
            {
              "siteId": "string",
              "name": "string",
              "deliveryAccount": "string",
              "city": "string",
              "country": "string"
            }
          ]
        ],
      },
    },
  }
}`,
  },
  {
    title: 'Retrieve updated cases',
    summary: 'How to retrieve updated cases',
    description:
      'Retrieve updated cases with all properties that were updated in the last day (24 * 60 minutes).',
    stringCodeRequest: `query RetrieveUpdatedCases {
  viewer {
    account {
      cases(id: "CASE_ID", serviceId: "SERVICE_ID", lastModifiedMinutes: 1440, customerReference: "CUSTOMER_REFERENCE") {
        id
        subject
        createdDate
        customerReference
        lastModifiedDate
        origin
        startOfIncident
        communications {
          message
          type
          timestamp
          sentBy
        }
        site {
          siteId
        }
        service {
          serviceId
        }
        status
        type
      }
    }
  }
}`,
    stringCodeResponse: `{
  "data": {
    "viewer": {
      "account": {
        "cases": [
          {
            "id": "string",
            "subject": "string",
            "createdDate": "string",
            "customerReference": "string",
            "lastModifiedDate": "string",
            "origin": "string",
            "startOfIncident": "string",
            "communications": [
              {
                "message": "string",
                "type": "string",
                "timestamp": "string",
                "sentBy": "string"
              }
            ],
            "site": {
              "siteId": "string"
            },
            "service": {
              "serviceId": "string"
            },
            "status": "string",
            "type": "string"
          },
        ],
      },
    },
  }
}`,
  },
  {
    title: 'Create a case',
    summary: 'How to create a new case',
    description: `Using this mutation, you can create a new case.
The response by default  returns the Case object.

The fields with (__*__) are required.
- subject__*__
  * For Indcident type the values are:
    - RfoRequest
    - ServiceDegradation
    - ServiceDown
    - ServiceIntermittent
  * For NonIncident type the values are:
    - ActivationSupport
    - ChangeRequest
    - EngineerDispatch
    - InformationRequest
    - Other
    - SoftwareUpdate
    - UtilizationReport
- type__*__
  - Incident
  - NonIncident
- sent by - The e-mail or company that sent the message.
- incident start date (UTC) - This is the date that the incident was first reported (ISO 8601 format). (Required only when Type is "Incident")
- customer reference - This field can be used as a reference to your internal systems objects.
- service ID__*__ - The Service ID (or also known as Order ID) of your service.
- message__*__ - The contents of the message.`,
    stringCodeRequest: `mutation CreateCase($subject: String!, $type: String!, $startOfIncident: String, $customerReference: String, $serviceId: String!, $message: String!, $sentBy: String) {
  createCase(subject: $subject, type: $type, startOfIncident: $startOfIncident, customerReference: $customerReference, serviceId: $serviceId, message: $message, sentBy: $sentBy) {
    id
  }
}`,
    stringCodeResponse: `{
  "data": {
    "createCase": {
      "id": "string"
    }
  }
}`,
  },
  {
    title: 'Reply to case',
    summary: 'How to reply to a case',
    description: `Using this mutation, you can reply to a case.
The fields with (__*__) are required.

- id (__*__) - Case ID
- message (__*__) - The contents of the message.
- sent by - The e-mail of the person who sent the message.`,
    stringCodeRequest: `mutation ReplyToCase($caseId: String!, $message: String!, $sentBy: String) {
  replyToCase(caseId: $caseId, message: $message, sentBy: $sentBy)
}`,
    stringCodeResponse: `{
  "data": {
    "replyToCase": "string"
  }
}`,
  },
  {
    title: 'Add customer reference',
    summary: 'How to add a customer reference to a case',
    description: `Using this mutation, you can add a customer reference to an existing case.
The fields with (__*__) are required.

- caseId (__*__) - Case ID
- customerReference (__*__) - The contents of the customer reference (i.e. your internal systems reference id).
`,
    stringCodeRequest: `mutation AddCustomerReference ($caseId: String!, $customerReference: String!) {
  addCustomerReference(caseId: $caseId, customerReference: $customerReference)
}`,
    stringCodeResponse: `{
  "data": {
    "addCustomerReference": "string"
  }
}`,
  },
]

export default customExamples
