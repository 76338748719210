import React, { useCallback, useState } from 'react'
import { useQuery, useMutation, useQueryCache } from 'react-query'

import { Mutations, Queries, API_CLIENTS, MODALS_CONFIG, REVOKE_API_CLIENT } from 'consts'
import { REVEAL, REVOKE } from 'consts/modals'
import { Box, Loader } from 'components/UI'
import { useGraphQLClient } from 'hooks'

import { ClientIDModal, ClientRevokeModal, Error } from './'

const ManageKeys = () => {
  const cache = useQueryCache()
  const client = useGraphQLClient()
  const [modalData, setModalData] = useState(null)

  // INFO: APIClients query
  const {
    isLoading: isApiClientsLoading,
    data: apiClients,
    error: apiClientsError,
    refetch: refetchApiClients,
  } = useQuery(
    API_CLIENTS,
    async () => {
      const { viewer } = await client.request(Queries[API_CLIENTS])

      return viewer.apiClients
    },
    { enabled: client !== null, retry: 5 }
  )

  // INFO: Revoke mutation
  const [
    revokeMutation,
    { isLoading: isRevokeInProgress, reset: resetRevokeState, error: revokeMutationError },
  ] = useMutation(
    async (apiClientId) => {
      await client.request(Mutations[REVOKE_API_CLIENT], { apiClientId })
    },
    {
      onSuccess: () => {
        cache.invalidateQueries(API_CLIENTS)
        handleCloseModal()
      },
      onError: (error) => {
        // INFO: Handle non 500 errors
        if (error?.response?.status !== 500) {
          cache.invalidateQueries(API_CLIENTS)
          handleCloseModal()
        }
      },
    }
  )

  const handleRevokeApiClient = async (isConfirmed) => {
    if (!isConfirmed) {
      setModalData(null)
      return
    }

    await revokeMutation(modalData?.apiClientId)
  }
  // TODO: Disable close button while revoke in progress
  const handleCloseModal = useCallback(() => {
    setModalData(null)
    resetRevokeState()
  }, [resetRevokeState])

  if (apiClientsError) return <Error message="apiClientsError" onClick={refetchApiClients} />
  // INFO: Deal with 500 errors, happen only when the server is down, regular GraphQL errors have status: 200
  if (revokeMutationError && revokeMutationError?.response?.status === 500) {
    return (
      <Error
        message="Internal Server Error - The key could not be revoked."
        onClick={handleCloseModal}
      />
    )
  }
  const revealModalType = MODALS_CONFIG.get(REVEAL).get('TYPE')
  const revokeModalType = MODALS_CONFIG.get(REVOKE).get('TYPE')
  // TODO: Animate keys on enter
  return (
    <>
      <ClientIDModal
        apiClientId={modalData?.apiClientId}
        name={modalData?.name}
        isVisible={modalData?.type === revealModalType}
        onClose={handleCloseModal}
      />
      <ClientRevokeModal
        isRevokeInProgress={isRevokeInProgress}
        isVisible={modalData?.type === revokeModalType}
        onClose={handleCloseModal}
        onRevoke={handleRevokeApiClient}
      />
      {isApiClientsLoading ? (
        <Loader>Loading your API keys...</Loader>
      ) : Array.isArray(apiClients) && !!apiClients.length ? (
        <>
          <Box className="manage-keys-header manage-keys">
            <Box>Name</Box>
            <Box>Scopes</Box>
            <Box>Client ID</Box>
            <Box>Revoke</Box>
          </Box>
          {apiClients.map(({ apiClientId, name, scopes }) => {
            return (
              <Box key={apiClientId} className="manage-keys">
                <Box>{name}</Box>
                <Box>{scopes.join(' ')}</Box>
                <Box>
                  <button
                    className="px-4 w-auto h-8 bg-exp-green-dark text-white text-base rounded-md shadow"
                    name="reveal-button"
                    type="button"
                    onClick={() => {
                      setModalData({ apiClientId, name, type: revealModalType })
                    }}
                  >
                    Reveal
                  </button>
                </Box>
                <Box>
                  <button
                    className="px-4 h-8 bg-exp-red text-white text-base rounded-md shadow"
                    name="revoke-button"
                    type="button"
                    onClick={() => setModalData({ apiClientId, type: revokeModalType })}
                  >
                    Revoke
                  </button>
                </Box>
              </Box>
            )
          })}
        </>
      ) : (
        <p className="text-exp-gray-dark">You don't have any API keys.</p>
      )}
    </>
  )
}

export default ManageKeys
