import React from 'react'

import { Heading, Modal } from 'components/UI'
import { REVEAL } from 'consts/modals'

const ClientIDModal = ({ apiClientId, isVisible, name, onClose }) => (
  <Modal isVisible={isVisible} onClose={onClose} type={REVEAL} wide>
    <div className="row">
      <Heading type="h4">Name:</Heading>
      <input name="key-name" readOnly value={name} />
    </div>
    <div className="row">
      <Heading type="h4">Client ID:</Heading>
      <input name="api-client-id" readOnly value={apiClientId} />
    </div>
  </Modal>
)

export default ClientIDModal
