import React from 'react'
import Markdown from 'react-markdown'

import { Box, CodeBlock, CodeDescription } from 'components/UI'
import { ExplorerLink } from 'components'

import { createAnchorTag, getRequestVariables } from 'utils'

const Example = ({
  description,
  stringCodeRequest = '',
  stringCodeResponse = '',
  summary = '',
  title = '',
}) => {
  const requestVariables = getRequestVariables(summary)

  return (
    <section key={createAnchorTag(summary)} className="flex flex-row min-h-screen">
      <Box id={summary} side="left" title={title}>
        <h2 className="mx-6 text-xl">{summary}</h2>
        <div className="markdown mx-6 my-4 text-gray-800">
          <Markdown source={description} />
        </div>
      </Box>
      <Box side="right" title="Example">
        <CodeDescription primary="Request Content-Types" secondary="application/json" />
        <CodeDescription primary="Query" />
        <CodeBlock language="graphql" stringCode={stringCodeRequest} />
        <ExplorerLink query={stringCodeRequest} variables={requestVariables} />
        <CodeDescription primary="Response Content-Types" secondary="application/json" />
        <CodeDescription primary="Response example" secondary="(200 OK)" />
        <CodeBlock language="json" stringCode={stringCodeResponse} />
      </Box>
    </section>
  )
}

export default Example
