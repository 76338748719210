import React from 'react'

const Modal = () => (
  <svg
    className="modal-background-svg"
    viewBox="0 0 900 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="900"
      height="320"
    >
      <rect width="900" height="320" rx="40" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <rect width="900" height="320" rx="40" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 190.499L38 201.132C75 211.766 150 233.033 225 228.307C300 222.399 375 190.499 450 153.873C525 116.065 600 73.5315 675 62.8981C750 52.2646 825 73.5315 863 84.1649L900 94.7983V350H863C825 350 750 350 675 350C600 350 525 350 450 350C375 350 300 350 225 350C150 350 75 350 38 350H0V190.499Z"
        fill="#EBE8FF"
      />
    </g>
  </svg>
)

export default Modal
