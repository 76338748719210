import React from 'react'
import GraphiQL from 'graphiql'
import { useLocation } from 'react-router-dom'
import useDocumentTitle from '@rehooks/document-title'

import { Loader } from 'components/UI'
import { DOCUMENT_TITLES } from 'consts'
import { useToken } from 'hooks'
import { graphQLFetcher } from 'utils'

const APIExplorer = () => {
  useDocumentTitle(DOCUMENT_TITLES.API_EXPLORER)
  const token = useToken()
  const search = new URLSearchParams(useLocation().search)
  const query = search.get('query') ? search.get('query') : undefined
  const variables = search.get('variables') ? search.get('variables') : undefined

  if (!token) return <Loader className="h-screen">loading...</Loader>

  return (
    <section className="m-0 w-full h-screen">
      <GraphiQL fetcher={graphQLFetcher(token)} query={query} variables={variables} />
    </section>
  )
}

export default APIExplorer
