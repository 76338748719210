import React from 'react'
import classnames from 'classnames'

export const Heading = ({
  type = 'h1',
  light = false,
  underline = false,
  className = '',
  children,
  ...rest
}) => {
  const underlineStyles = classnames('border-b-2 border-opacity-50', {
    'border-exp-gray-light': light,
    'border-exp-gray': !light,
  })

  switch (type) {
    case 'h1':
      return (
        <h1
          className={classnames(
            'mx-6 my-6 py-4 text-2xl font-eina-bold',
            className,
            underline ? underlineStyles : '',
            {
              'text-black': !light,
              'text-white': light,
            }
          )}
          {...rest}
        >
          {children}
        </h1>
      )
    case 'h2':
      return (
        <h2
          className={classnames(
            'mx-6 my-4 py-4 text-xl font-eina-bold',
            className,
            underline ? underlineStyles : '',
            {
              'text-black': !light,
              'text-white': light,
            }
          )}
          {...rest}
        >
          {children}
        </h2>
      )
    case 'h3':
      return (
        <h3
          className={classnames(
            'mx-6 my-4 py-4 text-xl font-eina-semi',
            className,
            underline ? underlineStyles : '',
            {
              'text-exp-gray-light': !light,
              'text-white': light,
            }
          )}
          {...rest}
        >
          {children}
        </h3>
      )
    case 'h4':
      return (
        <h4
          className={classnames(
            'mx-6 py-2 text-base font-eina-semi',
            className,
            underline ? underlineStyles : '',
            {
              'text-exp-gray-dark': !light,
              'text-white': light,
            }
          )}
          {...rest}
        >
          {children}
        </h4>
      )
    default:
      return null
  }
}

export default Heading
