import React from 'react'
import classnames from 'classnames'

const Link = ({ children, href, className = '' }) => {
  return (
    <a className={classnames(className)} href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

export default Link
