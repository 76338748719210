import React from 'react'

import { Modal } from 'components/UI'
import { NOTIFICATION } from 'consts/modals'

const NotificationModal = ({ isVisible, message, onClose }) => (
  <Modal onClose={onClose} isVisible={isVisible} type={NOTIFICATION} wide>
    {message && <p>{message}</p>}
  </Modal>
)

export default NotificationModal
