import React from 'react'

const Programming = () => (
  <svg className="modal-sign" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
      fill="#FF68A5"
    />
    <path
      d="M42.9688 135.109L107.576 199.716C159.265 195.844 200 152.68 200 100C200 96.866 199.848 93.7676 199.566 90.707L157.031 48.1719L42.9688 135.109Z"
      fill="#FF0099"
    />
    <path d="M117.079 148.486V135.109L95.541 130.65L100 148.486H117.079Z" fill="#9ABADB" />
    <path d="M82.9209 148.486H99.9998V130.65L82.9209 135.109V148.486Z" fill="#D1E8FF" />
    <path d="M157.031 115.043L95.541 110.584L100 135.109H157.031V115.043Z" fill="#D1E8FF" />
    <path d="M42.9688 115.043V135.109H100V110.584L42.9688 115.043Z" fill="#F5F5FF" />
    <path d="M127.112 145.145H100L97.7705 148.486L100 151.828H127.112V145.145Z" fill="#D1E8FF" />
    <path d="M100 145.145H72.8867V151.828H100V145.145Z" fill="#F5F5FF" />
    <path d="M157.031 48.1719H100L95.541 81.6074L100 115.043H157.031V48.1719Z" fill="#294B6E" />
    <path d="M100 48.1719H42.9688V115.043H100V48.1719Z" fill="#6283A3" />
    <path
      d="M82.8076 99.3125L65.1025 81.6074L82.8076 63.9023L87.5334 68.6281L74.5541 81.6074L87.5334 94.5867L82.8076 99.3125Z"
      fill="#F5F5FF"
    />
    <path
      d="M117.192 99.3125L112.466 94.5867L125.446 81.6074L112.466 68.6281L117.192 63.9023L134.897 81.6074L117.192 99.3125Z"
      fill="#D1E8FF"
    />
    <path
      d="M92.5985 101.67L99.1538 102.974L107.395 61.5423L100.84 60.2383L92.5985 101.67Z"
      fill="#D1E8FF"
    />
  </svg>
)

export default Programming
