import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { ExpereoOneLogo } from './UI'
import NavigationLink from './NavigationLink'

import { getSwaggerDefinitions } from 'utils'

import { config, customExamples } from 'consts'

const Sidebar = ({ onLogout, user }) => {
  const location = useLocation()
  const definitions = getSwaggerDefinitions()

  useEffect(() => {
    const { hash, pathname } = location

    if (pathname !== '/') return

    // TODO: animate when browser navigation buttons are used
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1)).scrollIntoView({ behavior: 'smooth' })
    } else {
      document.getElementById('introduction').scrollIntoView({ behavior: 'smooth' })
    }
  }, [location])

  return (
    <header className="p-8 -ml-64 w-64 fixed z-10 h-full flex flex-col justify-start items-center bg-gray-300 shadow-xl overflow-y-auto scrolling-touch overscroll-contain">
      <div className="flex flex-row justify-start w-full">
        <ExpereoOneLogo />
      </div>
      {!!user && (
        <div className="w-full flex flex-col mt-2">
          <p>
            {user.attributes.given_name} {user.attributes.family_name}
          </p>
          <button className="font-bold text-sm text-left mt-2" type="button" onClick={onLogout}>
            Log out
          </button>
        </div>
      )}
      {!user && (
        <div className="w-full flex flex-col mt-2 text-left">
          <a
            href={`${config.EXPEREO_URL[process.env.REACT_APP_ENV]}/login?${encodeURIComponent(
              'redirect_to=devportal'
            )}`}
          >
            Log in
          </a>
        </div>
      )}
      <nav className="w-full mt-10">
        <ul>
          <li>
            <NavLink exact to="/" activeClassName="active">
              Introduction
            </NavLink>
            <ul className="mt-2">
              <NavigationLink name="authentication_information" title="Authentication" />
              <NavigationLink name="what_are_scopes" title="What are scopes?" />
            </ul>
          </li>
          <li>
            <NavLink exact to="/apiexplorer" activeClassName="active">
              API Explorer
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/credentials" activeClassName="active">
              API Credentials
            </NavLink>
          </li>
        </ul>
        <p>Examples</p>
        <ul>
          {customExamples.map((example) => (
            <NavigationLink key={example.summary} name={example.summary} title={example.title} />
          ))}
        </ul>
        <p>Definitions</p>
        <ul>
          {definitions.map(([name]) => (
            <NavigationLink key={name} name={name} title={name} />
          ))}
        </ul>
        <ul className="ml-0 mt-6">
          <NavigationLink name="change_policy" title="Change Policy" />
        </ul>
      </nav>
    </header>
  )
}

export default Sidebar
