import React from 'react'

import { Modal } from 'components/UI'
import { REVOKE } from 'consts/modals'

const noop = () => {}

const ClientRevokeModal = ({ isRevokeInProgress, isVisible, onRevoke = noop, onClose }) => (
  <Modal isVisible={isVisible} onClose={onClose} type={REVOKE} wide>
    <div className="modal-button-group">
      <button
        type="button"
        className="mr-6 px-4 w-2/5 h-10 bg-exp-green text-white text-base rounded-md focus:shadow-outline disabled:bg-exp-gray-base disabled:cursor-not-allowed"
        onClick={(evt) => {
          evt.preventDefault()
          onRevoke(true)
        }}
        disabled={isRevokeInProgress}
      >
        Confirm
      </button>
      <button
        type="button"
        className="px-4 w-2/5 h-10 bg-exp-gray-base text-white text-base rounded-md focus:shadow-outline disabled:bg-exp-gray-base disabled:cursor-not-allowed"
        onClick={(evt) => {
          evt.preventDefault()
          onRevoke(false)
        }}
        disabled={isRevokeInProgress}
      >
        Cancel
      </button>
    </div>
  </Modal>
)

export default ClientRevokeModal
