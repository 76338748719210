import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom'
import useDocumentTitle from '@rehooks/document-title'

import { Layout } from 'components'
import { Box, CodeBlock, Heading, Link, NotificationModal } from 'components/UI'
import { customExamples, oauth2Examples, pagesMap, DOCUMENT_TITLES } from 'consts'
import { getSwaggerDefinitions } from 'utils'

import { ChangePolicy, Definition, Example, Introduction } from './components'

const Home = () => {
  useDocumentTitle(DOCUMENT_TITLES.INTRODUCTION)
  const [notification, setNotification] = useState('')
  const location = useLocation()
  const history = useHistory()

  const definitions = getSwaggerDefinitions()

  useEffect(() => {
    let notificationMessage = ''

    if (location?.state?.role) {
      notificationMessage = `You are logged in with a "${
        location.state.role
      }" account. You need to log in with a "Developer" account in order to navigate to ${pagesMap.get(
        location.state.from.pathname
      )} page.`
    } else if (location?.state?.from?.pathname) {
      notificationMessage = `You need to log in with a "Developer" account in order to navigate to ${pagesMap.get(
        location.state.from.pathname
      )} page.`
    }

    setNotification(notificationMessage)
  }, [location])

  return (
    <Layout className="home-layout flex-col w-full">
      <NotificationModal
        isVisible={notification !== ''}
        message={notification}
        onClose={() => {
          setNotification('')
          history.replace('/')
        }}
      />
      <Introduction />
      <section className="flex flex-row">
        <Box side="left">
          <Heading type="h1" id="authentication_information" underline>
            Authentication
          </Heading>
          <div className="mx-6 pt-4">
            <p>
              The <strong>expereoOne</strong> API uses OAuth 2.0 to authenticate requests. You can
              view and manage your keys in the{' '}
              <RouterLink to="/credentials">API Credentials</RouterLink> page.
            </p>
            <p className="mt-4">
              Once you create a new set of API keys, you will be able to authenticate with our auth
              server. In order to authenticate you need to send your API keys (public as{' '}
              <code>CLIENT_ID</code> and private as <code>CLIENT_SECRET</code>) and the relevant{' '}
              <RouterLink to={{ pathname: '/', hash: 'what_are_scopes' }}>scope</RouterLink>.
            </p>
            <p className="mt-4">
              The required scope is <code>customerapi/basic.read</code>. Additional scopes should be
              separated by space i.e. <code>customerapi/basic.read customerapi/support.read</code>
            </p>
            <p className="mt-6">
              Once successfully authenticated, you will receive a{' '}
              <Link href="https://jwt.io/">JSON Web Token</Link> which can be used to authenticate
              your GraphQL queries.
            </p>
          </div>
        </Box>
        <Box side="right">
          <Heading type="h1" light underline>
            Authentication examples
          </Heading>
          <div>
            {oauth2Examples.map(({ code, language, platform }) => {
              return (
                <div key={platform}>
                  <h3 className="mx-6 text-lg text-white">{platform}</h3>
                  <CodeBlock id={platform} language={language} stringCode={code} />
                </div>
              )
            })}
          </div>
        </Box>
      </section>
      <section className="flex flex-row min-h-screen">
        <article className="flex flex-col w-1/2 relative">
          <Heading type="h1" id="what_are_scopes" underline>
            What are scopes?
          </Heading>
          <div className="mx-6 pt-4">
            <p className="mb-4 text-base text-exp-gray-dark">
              Whenever you authenticate, you need to determine what kind of data you need access to.
              We use scopes for that.
            </p>

            <p className="mb-4 text-base text-exp-gray-dark">
              Scopes are permissions that identify the scope of access an application will have to
              your <strong>expereoOne</strong> account. In other words, an access scope determines
              what an application can do and in which part of your account.
            </p>

            <p className="mb-4 text-base text-exp-gray-dark">
              Depending on what your goal is, your application might need only some access scopes,
              or it might need full access. It is important to keep in mind what functions
              specifically will this app perform.
            </p>

            <p className="mb-4 text-base text-exp-gray-dark">
              Below you can see the available scopes and the description of them:
            </p>

            <div className="scopes-info-container flex flex-col">
              <div className="row flex flex-row">
                <p>Name</p>
                <p>Description</p>
              </div>
              <div className="row flex flex-row">
                <p>
                  <code>customerapi/basic.read</code>
                </p>
                <p>
                  Basic read - gives you access to read your user information, your services and
                  sites
                </p>
              </div>
              <div className="row flex flex-row">
                <p>
                  <code>customerapi/support.read</code>
                </p>
                <p>Support read - allows you to read all the support information</p>
              </div>
              <div className="row flex flex-row">
                <p>
                  <code>customerapi/support.read-write</code>
                </p>
                <p>
                  Support read & write - allows you to read and write on all the support information
                  (i.e. reply to cases, create cases etc.)
                </p>
              </div>
            </div>

            <p className="my-4 p-2 font-eina-semi rounded-md border-solid border-2 border-exp-yellow">
              Please note that scopes cannot be changed after the API keys have been generated.
              You’ll need to create a new token for that.
            </p>
          </div>
        </article>
        <article className="flex flex-col w-1/2"></article>
      </section>
      {customExamples.map((example) => (
        <Example key={`example__${example.title}`} {...example} />
      ))}
      {definitions.map(([name, definition]) => (
        <Definition key={name} name={name} definition={definition} definitions={definitions} />
      ))}

      <ChangePolicy />
    </Layout>
  )
}

export default Home
