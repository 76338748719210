import React, { useState } from 'react'
import { useMutation, useQueryCache } from 'react-query'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { Mutations, API_CLIENTS, CREATE_API_CLIENT, SCOPES, VALIDATIONS } from 'consts'
import { Box, Checkbox, Heading, Loader } from 'components/UI'
import { useGraphQLClient } from 'hooks'

import { ClientCredentialsModal, Error, SubmitButton } from './'

const CreateAPIKey = () => {
  const cache = useQueryCache()
  const client = useGraphQLClient()
  const { regExp: keyNameRegExp, errorMessage: keyNameErrorMessage } = VALIDATIONS.KEY_NAME
  const { regExp: nameTakenRegExp } = VALIDATIONS.NAME_TAKEN
  const [keyName, setKeyName] = useState('')
  const [validationError, setValidationError] = useState(null)
  // INFO: customerapi/basic.read is required
  const [scopes, setScopes] = useState([SCOPES[0].name])

  // INFO: create API client mutation
  const [
    createMutation,
    {
      isLoading: isCreateApiClientLoading,
      data: apiClient,
      error: apiClientError,
      reset: resetApiClient,
    },
  ] = useMutation(
    async () => {
      const { createApiClient } = await client.request(Mutations[CREATE_API_CLIENT], {
        name: keyName,
        scopes,
      })

      return createApiClient
    },
    {
      onSuccess: () => cache.invalidateQueries(API_CLIENTS),
    }
  )

  const handleKeyNameChange = (evt) => {
    const name = evt.target.value

    if (keyNameRegExp.test(name)) {
      setValidationError(keyNameErrorMessage)
    } else {
      setValidationError(null)
    }

    setKeyName(name)
  }
  const handleSelectScope = (evt) => {
    const { name } = evt.target

    setScopes((scopes) =>
      scopes.includes(name) ? scopes.filter((scope) => scope !== name) : [...scopes, name]
    )
  }
  const handleCreateApiClient = async () => await createMutation({ keyName, scopes })

  const resetModal = () => {
    setKeyName('')
    setScopes([SCOPES[0].name])
    resetApiClient()
    setValidationError(null)
  }

  if (isCreateApiClientLoading) return <Loader>Creating your API Client Credentials...</Loader>
  if (apiClientError) {
    const errorMessage = apiClientError?.response?.errors[0]?.message
    const isNameTaken = nameTakenRegExp.test(errorMessage)
    const message = isNameTaken ? errorMessage : void 0
    const buttonText = `Try ${isNameTaken ? 'a different name' : 'again'}`

    return <Error message={message} butonText={buttonText} onClick={resetModal} />
  }

  // TODO: Fix focus on checkboxes when using tab
  // TODO: Add custom outline to focusable elements (links, buttons)
  return (
    <>
      <ClientCredentialsModal {...apiClient} isVisible={!!apiClient} onClose={resetModal} />
      <Heading type="h4" className="ml-0 mt-4 mb-2">
        API Key name
      </Heading>
      <Box className="input-container relative">
        <input
          className={classnames(
            'h-12 p-4 text-exp-gray w-full bg-white leading-8 rounded-md block border border-exp-gray-light outline-none focus:shadow-outline focus:border-transparent placeholder-exp-gray placeholder-opacity-75 transition-colors duration-300 ease-in-out',
            {
              'border-exp-gray-light': !validationError,
              'border-exp-red': !!validationError,
              'focus:shadow-error': !!validationError,
            }
          )}
          name="key-name"
          type="text"
          value={keyName}
          placeholder="Name of your API Key (i.e. Customer Service API Key)"
          onChange={handleKeyNameChange}
        />
        {validationError !== null && (
          <p className="text-exp-red absolute mt-2">{validationError}</p>
        )}
      </Box>
      <Box flex className="mt-8 mb-4 flex-col">
        <Box flex className="mb-4 flex-row justify-between">
          <p>Select scopes:</p>
          <Link to={{ pathname: '/', hash: '#what_are_scopes' }}>What are scopes?</Link>
        </Box>
        <Box flex className="flex-row">
          {SCOPES.map(({ id, name, description }) => (
            <Checkbox
              key={name}
              id={id}
              name={name}
              onChange={handleSelectScope}
              checked={scopes.includes(name)}
              disabled={name === SCOPES[0].name}
              label={description}
            />
          ))}
        </Box>
      </Box>
      <SubmitButton
        disabled={!keyName || isCreateApiClientLoading || !!validationError}
        onClick={handleCreateApiClient}
      />
    </>
  )
}

export default CreateAPIKey
