import React from 'react'
import { Link } from 'react-router-dom'

const ExplorerLink = ({ name = 'Try it now', query, variables }) => {
  let search = `?query=${encodeURIComponent(query)}`

  if (variables) {
    search = `${search}&variables=${encodeURIComponent(JSON.stringify(variables, null, 2))}`
  }

  return (
    <Link className="mx-6 my-4 text-exp-blue" to={{ pathname: '/apiexplorer', search }}>
      {name}
    </Link>
  )
}

export default ExplorerLink
