import React from 'react'
import classnames from 'classnames'

import { Checkmark } from './'

const noop = () => {}

const Checkbox = ({ checked, disabled, id, name, onChange = noop, label }) => {
  return (
    <div className="mr-6">
      <label
        className={classnames('flex flex-row justify-start items-center', {
          'cursor-pointer': !disabled,
          'cursor-not-allowed': disabled,
        })}
        htmlFor={id}
      >
        <Checkmark checked={checked} disabled={disabled} />
        <input
          checked={checked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          type="checkbox"
          className="hidden"
        />
        <span className="ml-2 my-0">{label}</span>
      </label>
    </div>
  )
}

export default Checkbox
