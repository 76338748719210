import { gql } from 'graphql-request'

export const CREATE_API_CLIENT = 'createApiClient'
export const REVOKE_API_CLIENT = 'revokeApiClient'

const mutations = {
  [CREATE_API_CLIENT]: gql`
    mutation CreateApiClient($name: String, $scopes: [String]) {
      createApiClient(name: $name, scopes: $scopes) {
        apiClientId
        clientSecret
        name
        scopes {
          name
          description
        }
      }
    }
  `,
  [REVOKE_API_CLIENT]: gql`
    mutation RevokeApiClient($apiClientId: String!) {
      revokeApiClient(apiClientId: $apiClientId)
    }
  `,
}

export default mutations
