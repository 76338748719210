import { gql } from 'graphql-request'

export const API_CLIENTS = 'apiClients'

const queries = {
  [API_CLIENTS]: gql`
    query ApiClients {
      viewer {
        apiClients {
          apiKey
          apiClientId
          name
          scopes
        }
      }
    }
  `,
}

export default queries
