import React from 'react'

import { Heading } from 'components/UI'
import { config } from 'consts'
import swagger from 'docs/swagger'

const Endpoints = () => (
  <section className="sticky top-0" data-testid="api-endpoints-section">
    <Heading type="h1" light underline>
      API endpoints
    </Heading>
    <div className="mx-6 mb-4 flex flex-col divide-exp-gray-light divide-solid divide-y-2 divide-opacity-25">
      {swagger.servers.map(({ description, url }) => {
        const displayURL =
          description === 'GraphQL'
            ? config.API[process.env.REACT_APP_ENV]
            : config.AUTH_SERVERS[process.env.REACT_APP_ENV]

        return (
          <div key={url} className="mb-4 pt-4 flex flex-col">
            <p className="mb-4 text-white text-lg">{description}</p>
            <input
              className="before bg-exp-gray-darkest text-white opacity-75 text-base w-auto p-4 h-12 rounded-l border-black"
              name={`${description.toLowerCase()}-endpoint`}
              type="text"
              value={displayURL}
              readOnly
            />
          </div>
        )
      })}
    </div>
  </section>
)

export default Endpoints
