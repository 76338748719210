import React from 'react'

import { Box, CodeBlock, CodeDescription, Heading } from 'components/UI'
import { createDefinitionProps, createResponseBody } from 'utils'
import { PRIMITIVES } from 'consts'

import Properties from './Properties'

const Definition = ({ name, definition, definitions }) => {
  const definitionProps = createDefinitionProps(definition)
  const responseBody = createResponseBody(definition?.properties, definitions)

  return (
    <section className="h-auto min-h-screen flex flex-row mt-6 pt-6">
      <Box id={name} side="left" title={name}>
        <Heading type="h4">{definition.description}</Heading>
        {!!definitionProps.length && (
          <>
            <Heading type="h3" className="mb-0">
              Properties:
            </Heading>
            {definitionProps.map(([name, type, description, required]) => (
              <Properties
                description={description}
                key={name}
                name={name}
                required={required}
                type={type}
              />
            ))}
          </>
        )}
      </Box>
      <Box side="right" title={name}>
        {PRIMITIVES.includes(name) ? (
          <p className="mx-4 my-2 text-gray-300">{definition?.description}</p>
        ) : (
          <>
            <CodeDescription primary="Response Content-Types" secondary="application/json" />
            <CodeBlock language="json" stringCode={responseBody} />
          </>
        )}
      </Box>
    </section>
  )
}

export default Definition
