import React from 'react'

const Command = () => (
  <svg className="modal-sign" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M200 100C200 155.229 155.229 200 100 200C44.7708 200 0 155.229 0 100C0 44.7708 44.7708 0 100 0C155.229 0 200 44.7708 200 100Z"
      fill="#40A3FF"
    />
    <path
      d="M200 102.5C200 90.9639 198.815 80.2065 195 70L162.699 42.1288C161.474 40.8206 159.733 40 157.81 40H44.7099C41.0195 40 38 43.0181 38 46.7066V134.2C38 136.084 38.7881 137.793 40.0526 139.014C40.0526 139.014 78.7552 180.445 98.5 200C100.234 200.096 101.979 200.149 103.738 200.149C155.51 200.149 200 154.247 200 102.5Z"
      fill="#3383CC"
    />
    <path d="M81 138H121V163H81V138Z" fill="#DBDBDB" />
    <path d="M101 138H121V163H101V138Z" fill="#B4B4B4" />
    <path
      d="M157.316 141H44.6836C41.0071 141 38 137.981 38 134.287V46.7128C38 43.0208 41.0071 40 44.6836 40H157.316C160.993 40 164 43.0208 164 46.7128V134.287C164 137.979 160.993 141 157.316 141Z"
      fill="#EAEAEA"
    />
    <path
      d="M164 46.7143V134.289C164 137.982 160.992 141 157.319 141H101V40H157.319C160.992 40 164 43.0223 164 46.7143Z"
      fill="#C9C9C9"
    />
    <path
      d="M106 131.5C106 134.539 103.761 137 101 137C98.239 137 96 134.539 96 131.5C96 128.463 98.239 126 101 126C103.761 126 106 128.463 106 131.5Z"
      fill="#C5C5C5"
    />
    <path
      d="M106 131.497C106 134.536 103.764 137 101 137V126C103.764 126 106 128.464 106 131.497Z"
      fill="#858585"
    />
    <path d="M38 51H164V122H38V51Z" fill="#57555C" />
    <path
      d="M60 65.8448L48 73V69.5087L55.4392 64.986L48 60.3482V57L60 64.2703V65.8448Z"
      fill="white"
    />
    <path d="M63 73V70H74V73H63Z" fill="white" />
    <path d="M101 51H164V122H101V51Z" fill="#2A2A2C" />
    <path d="M74 160H128V172H74V160Z" fill="#F0F0F0" />
    <path d="M101 160H128V172H101V160Z" fill="#CDCDCD" />
  </svg>
)

export default Command
