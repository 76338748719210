import { useEffect } from 'react'

function useKeyPress(targetKey, fn) {
  useEffect(() => {
    function downHandler({ key }) {
      if (key === targetKey) {
        fn()
      }
    }

    window.addEventListener('keydown', downHandler, true)

    return () => {
      window.removeEventListener('keydown', downHandler, true)
    }
  }, [targetKey, fn])
}

export default useKeyPress
