import React from 'react'

const Cross = () => (
  <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="2.70711" y1="1.57716" x2="30.9914" y2="29.8614" stroke="#ACB5C4" strokeWidth="2" />
    <line x1="1.29289" y1="29.5772" x2="29.5772" y2="1.29289" stroke="#ACB5C4" strokeWidth="2" />
  </svg>
)

export default Cross
