import React from 'react'
import { Link } from 'react-router-dom'
import Markdown from 'react-markdown'

import { PRIMITIVES } from 'consts'
import { createAnchorTag } from 'utils'

const Properties = ({ name, type, description, required }) => (
  <div key={name + Math.random()} className="definition-properties">
    <p className="text-exp-gray-dark">{name}:</p>
    {Array.isArray(type) ? (
      <>
        <span>
          {`Array <`}
          {!PRIMITIVES.find((el) => el.toLowerCase() === type[1]) ? (
            <Link to={{ pathname: `/`, hash: `#${createAnchorTag(type[1])}` }}>{type[1]}</Link>
          ) : (
            <span>{type[1]}</span>
          )}
          {`>`}
        </span>
      </>
    ) : (
      <>
        {!PRIMITIVES.find((el) => el.toLowerCase() === type) ? (
          <Link to={{ pathname: `/`, hash: `#${createAnchorTag(type)}` }}>{type}</Link>
        ) : (
          <span>{type}</span>
        )}
      </>
    )}
    <span className="markdown properties text-exp-gray">
      <Markdown source={description} />
    </span>
    {required && <span className="text-red-600">Required</span>}
  </div>
)

export default Properties
