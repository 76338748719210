import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'

const CodeBlock = ({ language = 'graphql', stringCode = '' }) => (
  <div className="mx-6 my-4">
    <SyntaxHighlighter language={language} style={dracula}>
      {stringCode}
    </SyntaxHighlighter>
  </div>
)

export default CodeBlock
