export const PRIMITIVES = ['String', 'Boolean', 'Int', 'MultiplierPath']
export const EXCLUDED_DEFINITIONS = [...PRIMITIVES, 'Viewer']

export const SCOPES = [
  {
    name: 'customerapi/basic.read',
    id: 'basic_read',
    description: 'Basic read',
  },
  {
    name: 'customerapi/support.read',
    id: 'support_read',
    description: 'Support read',
  },
  {
    name: 'customerapi/support.read-write',
    id: 'support_read-write',
    description: 'Support read & write',
  },
]

export const REQUEST_VARIABLES_MAP = {
  create_api_credentials: {
    name: 'NAME',
    scopes: ['customerapi/basic.read', 'ANOTHER_SCOPE'],
  },
  revoke_api_credentials: {
    apiClientId: 'API_CLIENT_ID',
  },
  how_to_create_a_new_case: {
    customerReference: 'CUSTOMER_REFERENCE',
    message: 'MESSAGE__REQUIRED',
    sentBy: 'SENT_BY',
    serviceId: 'SERVICE_ID__REQUIRED',
    startOfIncident: 'START_OF_INCIDENT',
    subject: 'SUBJECT__REQUIRED',
    type: 'TYPE__REQUIRED',
  },
  how_to_reply_to_a_case: {
    caseId: 'CASE_ID__REQUIRED',
    message: 'MESSAGE__REQUIRED',
    sentBy: 'SENT_BY',
  },
  how_to_add_a_customer_reference_to_a_case: {
    caseId: 'CASE_ID__REQUIRED',
    customerReference: 'CUSTOMER_REFERENCE__REQUIRED',
  },
}

export const VALIDATIONS = {
  KEY_NAME: {
    regExp: /[^a-z0-9\s.,@_\-+=]/i,
    errorMessage: 'Invalid characters used as the API Key name',
  },
  NAME_TAKEN: {
    regExp: /name.*taken/i,
    errorMessage: 'This API Key name is already taken',
  },
}

const DEVELOPER_EXPEREO_ONE = 'expereoOne - Developer Portal'
export const DOCUMENT_TITLES = {
  API_CREDENTIALS: `${DEVELOPER_EXPEREO_ONE} | API Credentials`,
  API_EXPLORER: `${DEVELOPER_EXPEREO_ONE} | API Explorer`,
  INTRODUCTION: `${DEVELOPER_EXPEREO_ONE} | Introduction`,
}

export const pagesMap = new Map([
  ['/credentials', 'API Credentials'],
  ['/apiexplorer', 'API Explorer'],
])

export { default as customExamples } from './customExamples'
export { oauth2Examples } from './oauth2Examples'
export { awsConfig } from './awsConfig'
export { config, ENVIRONMENTS } from './config'
export { default as Queries, API_CLIENTS } from './GraphQL/queries'
export { default as Mutations, CREATE_API_CLIENT, REVOKE_API_CLIENT } from './GraphQL/mutations'
export { default as MODALS_CONFIG } from './modals'
