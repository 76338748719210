import { config } from './config'
import url from 'url'

const AUTH_SERVER_URL = config.AUTH_SERVERS[process.env.REACT_APP_ENV]
const parsedURL = url.parse(AUTH_SERVER_URL)

export const oauth2Examples = [
  {
    language: 'bash',
    platform: 'curl',
    code: `curl -X POST "${AUTH_SERVER_URL}" \\
  -H "Content-Type: application/x-www-form-urlencoded" \\
  -d "grant_type=client_credentials" \\
  -d "scope=customerapi/basic.read" \\
  -d "client_id={CLIENT_ID}" \\
  -d "client_secret={CLIENT_SECRET}"`,
  },
  {
    language: 'javascript',
    platform: 'Node.js',
    code: `const https = require('follow-redirects').https
const qs = require('querystring')

const options = {
  method: 'POST',
  hostname: '${parsedURL.hostname}',
  path: '${parsedURL.path}',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  maxRedirects: 20,
}

const req = https.request(options, function (res) {
  const chunks = []

  res.on('data', function (chunk) {
    chunks.push(chunk)
  })

  res.on('end', function (chunk) {
    const body = Buffer.concat(chunks)
    console.log(body.toString())
  })

  res.on('error', function (error) {
    console.error(error)
  })
})

const postData = qs.stringify({
  grant_type: 'client_credentials',
  scope: 'customerapi/basic.read',
  client_id: '{CLIENT_ID}',
  client_secret: '{CLIENT_SECRET}',
})

req.write(postData)

req.end()`,
  },
]
