import React from 'react'
import classnames from 'classnames'

import ExpereoOneLogo from './SVG/ExpereoOneLogo'

const Loader = ({ children, className }) => (
  <div
    className={classnames('flex flex-row flex-auto justify-center items-center', className)}
    data-testid="loader"
  >
    <div className="flex flex-col items-center">
      <ExpereoOneLogo />
      <p className="ml-2">{children}</p>
    </div>
  </div>
)

export default Loader
