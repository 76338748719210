import swagger from 'docs/swagger'
import { config, EXCLUDED_DEFINITIONS, REQUEST_VARIABLES_MAP } from 'consts'

export const getSwaggerExamples = () =>
  typeof swagger?.paths === 'object' ? Object.values(swagger.paths) : []

export const getSwaggerDefinitions = () =>
  typeof swagger?.definitions === 'object'
    ? Object.entries(swagger.definitions).filter(([name]) => !EXCLUDED_DEFINITIONS.includes(name))
    : []

export const createAnchorTag = (str) => str.toLowerCase().replace(/\s/gi, '_')

export const parseRef = (ref) => {
  const arr = ref.split('/')

  return arr[arr.length - 1]
}

export const createDefinitionProps = ({ properties, required }) =>
  properties
    ? Object.entries(properties).reduce((accu, [key, val]) => {
        const isTypeArray = val?.type === 'array'
        const isPrimitive = typeof val.type !== 'undefined'
        const description = val?.description?.trimEnd() || ''
        let type

        if (isPrimitive) {
          type = isTypeArray
            ? [val.type, val.items.type ? val.items.type : parseRef(val.items.$ref)]
            : val.type
        } else {
          type = isTypeArray ? [parseRef(val.$ref), parseRef(val.items.$ref)] : parseRef(val.$ref)
        }

        accu.push([key, type, description, required.includes(key)])

        return accu
      }, [])
    : []

// INFO: In order to avoid circular dependencies overload we bailout of parseProperties after first level
let parsePropertiesLevel = 0
export const parseProperties = (properties, definitions) => {
  const resp = Object.entries(properties).reduce((accu, [key, val]) => {
    if (val.type === 'string') {
      accu[key] = val.type
    } else if (val.type === 'array') {
      if (val.items.type === 'string') {
        accu[key] = [val.items.type]
      } else if (val.items.$ref) {
        const ref = parseRef(val.items.$ref)
        const foundDef = definitions.find((def) => def[0] === ref)
        parsePropertiesLevel += 1
        if (parsePropertiesLevel <= 1) {
          accu[key] = parseProperties(foundDef[1].properties, definitions)
        }
      }
    } else if (val.$ref) {
      const ref = parseRef(val.$ref)
      const foundDef = definitions.find((def) => def[0] === ref)

      parsePropertiesLevel += 1
      if (parsePropertiesLevel <= 1) {
        accu[key] = parseProperties(foundDef[1].properties, definitions)
      }
    }

    return accu
  }, {})

  parsePropertiesLevel = 0

  return resp
}

export const createResponseBody = (properties, definitions) => {
  const body = { data: {} }

  if (properties) {
    body.data = parseProperties(properties, definitions)
  }

  try {
    return JSON.stringify(body, null, 2)
  } catch (err) {
    throw new Error('Could not stringify properties.', err)
  }
}

export const getResponseExampleFromPost = (post, definitions) => {
  const { description, schema } = post?.responses?.[200]
  const ref = schema?.$ref && parseRef(schema.$ref)
  const definition = ref && definitions.find((def) => def[0] === ref)
  const body = { data: {} }
  if (definition?.[1]?.properties) {
    body.data = parseProperties(definition[1].properties, definitions)
  }
  /**
   * data: {
   *   revokeApiClient: "Success"
   * }
   */

  try {
    return { description, stringCode: JSON.stringify(body, null, 2) }
  } catch (err) {
    throw new Error('Could not stringify example properties.', err)
  }
}

export const getRequestVariables = (summary) => {
  // TODO: Create a function that generates values
  const key = createAnchorTag(summary)

  if (key in REQUEST_VARIABLES_MAP) return REQUEST_VARIABLES_MAP[key]

  return {}
}

export const graphQLFetcher = (token) => (graphQLParams) =>
  fetch(config.API[process.env.REACT_APP_ENV], {
    method: 'post',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    body: JSON.stringify(graphQLParams),
  }).then((response) => response.json())
