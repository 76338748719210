import React from 'react'
import classnames from 'classnames'

import { Heading } from 'components/UI/Heading'
import { createAnchorTag } from 'utils'

const Box = ({ id, flex = false, side, title, children, className = '', ...rest }) => {
  if (side === 'left') {
    return (
      <article className={classnames('flex flex-col w-1/2', className)}>
        {title && (
          <Heading type="h1" id={id && createAnchorTag(id)} underline>
            {title}
          </Heading>
        )}
        {children}
      </article>
    )
  }

  if (side === 'right') {
    return (
      <article className={classnames('flex flex-col w-1/2', className)}>
        {title && (
          <Heading type="h1" light underline>
            {title ? title : 'Example'}
          </Heading>
        )}
        {children}
      </article>
    )
  }

  if (flex) {
    return (
      <div {...rest} className={classnames('flex', className)}>
        {children}
      </div>
    )
  }

  return (
    <div {...rest} className={className}>
      {children}
    </div>
  )
}

export default Box
