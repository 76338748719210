import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'

function useToken() {
  const [token, setToken] = useState(null)

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        setToken(token)
      } catch (error) {
        console.error('Could not get the JWT :: ', error)
      }
    }
    getToken()
  }, [])

  return token
}

export default useToken
