import React from 'react'

import { Box, Heading } from 'components/UI'
import { Endpoints, Layout } from 'components'
import { DOCUMENT_TITLES } from 'consts'
import useDocumentTitle from '@rehooks/document-title'

import { ManageKeys, CreateAPIKey } from './components'

const Credentials = () => {
  useDocumentTitle(DOCUMENT_TITLES.API_CREDENTIALS)
  // TOOD: animate checkboxes
  return (
    <Layout className="flex-col w-full min-h-screen">
      <section className="flex flex-row h-full w-full">
        <Box side="left">
          <Heading type="h1" underline>
            API Keys
          </Heading>
          <Heading type="h3" className="mt-0 mb-0" underline>
            Create your API Key
          </Heading>
          <Box className="api-key-container mx-6 flex flex-col">
            <CreateAPIKey />
          </Box>
          <Box className="mx-6 mt-2 mb-10 flex flex-col h-full">
            <Heading type="h3" className="ml-0 mr-0" underline>
              View and manage your existing API keys
            </Heading>
            <ManageKeys />
          </Box>
        </Box>
        <Box side="right">
          <Endpoints />
        </Box>
      </section>
    </Layout>
  )
}

export default Credentials
