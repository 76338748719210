import { useEffect, useState } from 'react'
import { GraphQLClient } from 'graphql-request'

import { config } from 'consts'
import { useToken } from 'hooks'

const GraphQLURL = config.API[process.env.REACT_APP_ENV]

function useGraphQLClient() {
  const token = useToken()
  const [client, setClient] = useState(null)

  useEffect(() => {
    if (token) {
      setClient(
        new GraphQLClient(GraphQLURL, {
          headers: { authorization: `Bearer ${token}` },
        })
      )
    }
  }, [token])

  return client
}

export default useGraphQLClient
