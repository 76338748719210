import React from 'react'

const Play = ({ className = '', fill = 'white' }) => (
  <svg
    className={className}
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6161 6.86753L1.15174 0.0970788C0.639854 -0.180004 0 0.169361 0 0.723526V14.2765C0 14.8306 0.639854 15.18 1.15174 14.9029L13.6161 8.13247C14.128 7.84322 14.128 7.14461 13.6161 6.86753Z"
      fill={fill}
    />
  </svg>
)

export default Play
