import React from 'react'

import { Box } from 'components/UI'

const noop = () => {}

const Error = ({
  message = 'Oops, there was an error :(',
  buttonText = 'Try again',
  onClick = noop,
}) => {
  return (
    <Box className="flex flex-col justify-center items-center h-full">
      <p className="mb-4 text-base font-eina-reg text-exp-red">{message}</p>
      <button
        className="px-4 h-8 bg-none text-base text-exp-gray-darkest font-eina-semi rounded-md"
        type="button"
        onClick={onClick}
      >
        {buttonText}
      </button>
    </Box>
  )
}

export default Error
