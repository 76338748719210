import React from 'react'
import { Link } from 'react-router-dom'

import { createAnchorTag } from 'utils'

const NavigationLink = ({ name, title }) => {
  return (
    <li key={name} className="mb-2">
      <Link to={{ pathname: `/`, hash: `#${createAnchorTag(name)}` }}>{title}</Link>
    </li>
  )
}

export default NavigationLink
