import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { config } from './consts'
import App from './App'

const { auth, preview } = config.GTM[process.env.REACT_APP_ENV]
const tagManagerArgs = { gtmId: config.GTM_ID, auth, preview }

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
