import React from 'react'

import { Box, Heading } from 'components/UI'

const ChangePolicy = () => {
  return (
    <section className="change-policy-container flex flex-row">
      <Box id="change_policy" title="Change Policy" side="left" className="mb-16">
        <div className="flex flex-col">
          <p className="mx-6 my-2">
            As part of our commitment to constantly improve our services and our expereoOne
            platform, we may add, modify or remove functionality available via the expereoOne API.
            This policy establishes an agreement between Expereo and our customers and aims to
            ensure the stability of the applications developed using the API. Expereo will use
            commercially reasonable efforts to notify you of any modifications to it. Expereo will
            not be liable to you or any third party for such modifications or any adverse effects
            resulting from such modifications.
          </p>
          <Heading type="h3" underline>
            Change Types
          </Heading>
          <p className="mx-6 my-2">
            The changes to the API may range from additions of new properties and values to breaking
            changes, such as changing the endpoint. To make sure you can quickly assess the impact,
            we’ve classified the changes to non-breaking, breaking, and emergency.
          </p>
          <Heading type="h4">Non-Breaking</Heading>
          <p className="mx-6 my-2">
            Non-breaking changes can be considered changes that extend an existing API or additions
            to the schema. They are considered non-breaking as they do not affect existing uses of
            the API. An application that is developed following GraphQL API client best practices
            should not be affected.
          </p>
          <p className="mx-6 my-2 font-eina-semi">Examples of non-breaking changes would be:</p>
          <ul className="mx-6 mb-2 list-inside list-disc">
            <li>Adding a new property to the services object</li>
            <li>Adding an optional property in the case creation mutation</li>
            <li>Adding a new object in the API</li>
            <li>Introducing or removing a rate limit</li>
          </ul>
          <Heading type="h4">Breaking</Heading>
          <p className="mx-6 my-2">
            Breaking Changes are considered the changes that would require code alteration of your
            application in order to avoid a service disruption.
          </p>
          <p className="mx-6 my-2 font-eina-semi">Examples of breaking changes would be:</p>
          <ul className="mx-6 mb-2 list-inside list-disc">
            <li>Removing the sites object</li>
            <li>
              Changing an optional case creation property to being required during case creation
            </li>
            <li>Renaming an property in services</li>
            <li>Changing a response to have a value outside of a documented range or format</li>
            <li>Adding a required request header</li>
          </ul>
          <Heading type="h4">Emergency</Heading>
          <p className="mx-6 my-2">
            While our goal is to adhere to the policies outlined above, there may be emergency
            situations that will require making changes without the required minimum notice.
            Emergency changes may be either breaking or non-breaking changes. These changes are only
            made in extremely rare and extenuating situations to address security or stability risks
            found in any part of the expereoOne platform.
          </p>
          <Heading type="h3" underline>
            Announcing changes
          </Heading>
          <p className="mx-6 my-2">
            You can use any of the following methods to get notified about API changes.
          </p>
          <p className="mx-6 my-2">
            E-mail: All the users with the developer role will automatically receive an e-mail
            notification whenever there is a breaking or emergency change in the API. If you don’t
            have a developer account yet, you can ask the admin of your expereoOne account in your
            organization, to create one for you.
          </p>
          <Heading type="h3" underline>
            Notice period
          </Heading>
          <table>
            <thead>
              <tr>
                <th>Change type</th>
                <th>Notification time</th>
                <th>Backwards compatibility</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Non-Breaking changes</td>
                <td>2 weeks</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>Breaking changes</td>
                <td>3 months</td>
                <td>6 months</td>
              </tr>
              <tr>
                <td>Emergency changes</td>
                <td>1 week</td>
                <td>6 months</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
      <Box side="right"></Box>
    </section>
  )
}

export default ChangePolicy
