import React from 'react'

const Precaution = () => (
  <svg className="modal-sign" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
      fill="#FFAF36"
    />
    <path
      d="M195.5 130.5L115.5 33.3284L37.1128 149.5L80.5 198C83 198.5 89.5089 200.433 99.9999 200C148.5 198 179.5 173.5 195.5 130.5Z"
      fill="#BF8329"
    />
    <path
      d="M85.4801 41.4741L38.1944 125.04C31.8885 136.184 39.9256 150 52.7145 150H147.286C160.074 150 168.111 136.184 161.806 125.04L114.52 41.4741C108.127 30.1753 91.8737 30.1753 85.4801 41.4741Z"
      fill="#FFE767"
    />
    <path
      d="M147.287 150H100V33C105.661 33 111.322 35.8244 114.521 41.4731L161.807 125.039C168.11 136.184 160.073 150 147.287 150Z"
      fill="#FFC100"
    />
    <path
      d="M147.14 156H52.8603C44.6461 156 37.2734 151.757 33.1379 144.65C29.0027 137.543 28.9517 129.028 33.0021 121.872L80.1414 38.587C84.248 31.3317 91.6723 27 100 27C108.328 27 115.752 31.3317 119.858 38.587L166.998 121.872C171.048 129.028 170.997 137.543 166.862 144.65C162.727 151.757 155.354 156 147.14 156ZM90.9082 44.6972L43.7685 127.982C41.9139 131.259 41.9373 135.157 43.8307 138.411C45.7242 141.665 49.0996 143.608 52.8603 143.608H147.14C150.9 143.608 154.276 141.665 156.169 138.411C158.063 135.158 158.086 131.259 156.231 127.982L109.092 44.6972C107.211 41.3756 103.813 39.3925 100 39.3925C96.1869 39.3925 92.7882 41.3756 90.9082 44.6972Z"
      fill="#576770"
    />
    <path
      d="M166.997 121.874L119.857 38.5853C117.807 34.9596 114.921 32.0633 111.513 30.0734C108.101 28.0835 104.165 27 100 27V39.3926C103.812 39.3926 107.213 41.3754 109.091 44.6967L156.231 127.982C158.087 131.261 158.062 135.159 156.167 138.413C154.275 141.667 150.899 143.607 147.14 143.607H100V156H147.14C155.354 156 162.726 151.758 166.863 144.652C170.996 137.546 171.049 129.03 166.997 121.874Z"
      fill="#324A5E"
    />
    <path d="M106 66V113H94V66H106Z" fill="#576770" />
    <path d="M100 66H106V113H100V66Z" fill="#324A5E" />
    <path
      d="M100 131C103.866 131 107 127.866 107 124C107 120.134 103.866 117 100 117C96.134 117 93 120.134 93 124C93 127.866 96.134 131 100 131Z"
      fill="#576770"
    />
    <path
      d="M107 124C107 127.864 103.868 131 100 131V117C103.868 117 107 120.132 107 124Z"
      fill="#324A5E"
    />
  </svg>
)

export default Precaution
