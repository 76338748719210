export const CREATE = 'createApiCredential'
export const REVEAL = 'revealApiKey'
export const REVOKE = 'revokeApiKey'
export const NOTIFICATION = 'notification'

const MODALS_CONFIG = new Map()

MODALS_CONFIG.set('ICONS', {
  CAUTION: 'caution',
  COMMAND: 'command',
  DEV: 'dev',
})

MODALS_CONFIG.set(
  CREATE,
  new Map([
    ['TYPE', CREATE],
    ['DESCRIPTION', "View client's API credentials: ID and secret"],
    ['TITLE', 'Your API Client Credentials'],
    ['LABEL', 'Your API Client Credentials modal'],
    ['ICON', 'dev'],
  ])
)

MODALS_CONFIG.set(
  REVEAL,
  new Map([
    ['TYPE', REVEAL],
    ['DESCRIPTION', 'Revoke API key'],
    ['TITLE', 'Your API Client name and ID'],
    ['LABEL', 'Your API Client name and ID modal'],
    ['ICON', 'dev'],
  ])
)

MODALS_CONFIG.set(
  REVOKE,
  new Map([
    ['TYPE', REVOKE],
    ['DESCRIPTION', "View client's API credentials: key name and ID"],
    ['TITLE', 'Do you want to revoke this key?'],
    ['LABEL', 'Revoke API key modal'],
    ['ICON', 'caution'],
  ])
)

MODALS_CONFIG.set(
  NOTIFICATION,
  new Map([
    ['TYPE', NOTIFICATION],
    ['DESCRIPTION', 'Notification that user cannot access this conent.'],
    ['TITLE', 'Sorry, you cannot access this content.'],
    ['LABEL', 'Unauthorized content modal.'],
    ['ICON', 'command'],
  ])
)

export default MODALS_CONFIG
