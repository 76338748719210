import React from 'react'
import { Redirect, Route } from 'react-router-dom'

function PrivateRoute({ children, user, ...rest }) {
  const isDeveloper = !!user && user.attributes['custom:role'] === 'Developer'

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isDeveloper ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                role: user?.attributes['custom:role'],
                from: location,
              },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
