import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, DevLogo, Heading, Link } from 'components/UI'
import { Endpoints } from 'components'

import { config } from 'consts'

const Introduction = () => (
  <section id="introduction" className="flex flex-row">
    <Box side="left">
      <div className="markdown pt-8 mr-16 flex flex-row relative justify-end">
        <DevLogo />
      </div>
      <Heading type="h1" underline className="mt-0 pt-0">
        Introduction
      </Heading>
      <div className="mx-6 flex flex-col">
        <p className="my-4 text-lg">
          This guide can be used by developers to get more information and eventually connect and
          consume the <strong>expereoOne</strong> APIs.
        </p>
        <h3 className="my-4 p-4 rounded-md font-bold text-lg text-exp-gray-dark bg-gray-300">
          The Expereo API is based on{' '}
          <Link href="https://en.wikipedia.org/wiki/GraphQL">GraphQL</Link> and returns{' '}
          <Link href="https://json.org">JSON</Link> encoded responses.
        </h3>
        <p>If you want to learn more about GraphQL, feel free to visit the following resources:</p>
        <ul className="flex flex-col list-disc list-inside">
          <li className="my-2">
            <Link href="http://graphql.com/">GraphQL official website</Link>
          </li>
          <li>
            <Link href="https://www.apollographql.com/blog/graphql/examples/4-simple-ways-to-call-a-graphql-api">
              4 simple ways to call a GraphQL API
            </Link>
          </li>
        </ul>
        <h2 className="text-exp-red text-md font-bold pt-6 pb-2">WARNING</h2>
        <p>The current version is unstable and the APIs may change.</p>
        <Heading type="h3" className="mt-8 ml-0" underline>
          Getting started
        </Heading>
        <h4 className="mb-4 text-exp-gray-dark">
          Before you start, you’ll need to complete a few steps to make sure you can securely
          authenticate and use the <strong>expereoOne</strong> API.
        </h4>
        <p className="mb-4">
          1. First of all, you need to log in as a <code>Developer</code>. You might need to create
          a new user from the{' '}
          <Link href={`${config.EXPEREO_URL[process.env.REACT_APP_ENV]}/profile`}>Profile</Link>{' '}
          page.
        </p>
        <p className="mb-4">
          2. Generate a new set of API keys from the{' '}
          <RouterLink to="/credentials">API Credentials</RouterLink> page. Remember to save the
          Client Secret Key in a safe place. It is being shown only once and cannot be retrieved
          later.
          <span className="block mt-4 p-2 font-eina-semi rounded-md border-solid border-2 border-exp-yellow">
            Client Secret Key should not be shared with anyone. Keep it safe!
          </span>
        </p>
        <p className="mb-4">
          3. Next, use the generated keys to authenticate and obtain a{' '}
          <Link href="https://jwt.io">JSON Web Token</Link> by{' '}
          <RouterLink to="/#authentication_information">following these steps</RouterLink>.
        </p>
        <p className="mb-4">
          4. Finally, use this token in all HTTP requests to our GraphQL endpoint. Pass the token by
          adding it to the request header as{' '}
          <code>Authorization: "Bearer PASTE_YOUR_TOKEN_HERE"</code>.
        </p>
        <p className="mb-4">Congratulations, now you have access to our GraphQL endpoint!</p>
      </div>
    </Box>
    <Box side="right">
      <Endpoints />
    </Box>
  </section>
)

export default Introduction
