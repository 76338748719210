import React from 'react'
import ReactDOM from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'
import classnames from 'classnames'

import { useKeyPress } from 'hooks'

import {
  Cross,
  Heading,
  ModalBackgroundSVG,
  ModalSignCommand,
  ModalSignPrecaution,
  ModalSignProgramming,
} from './'
import { MODALS_CONFIG } from 'consts'

const noop = () => {}

const Modal = ({ children, isVisible, onClose = noop, type, wide = false }) => {
  useKeyPress('Escape', onClose)

  const modalConfig = MODALS_CONFIG.get(type)
  const { CAUTION, COMMAND, DEV } = MODALS_CONFIG.get('ICONS')

  const modalContent = (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeInOut', duration: 0.3 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          className="modal-container"
        >
          <div className="modal-background">
            <ModalBackgroundSVG />
            <div
              aria-describedby={modalConfig.get('DESCRIPTION')}
              aria-labelledby={modalConfig.get('LABEL')}
              aria-modal="true"
              className={classnames('modal-content', { wide })}
              role="dialog"
            >
              {modalConfig.get('ICON') === DEV && <ModalSignProgramming />}
              {modalConfig.get('ICON') === CAUTION && <ModalSignPrecaution />}
              {modalConfig.get('ICON') === COMMAND && <ModalSignCommand />}
              <div className="modal-inner-content">
                <Heading type="h2" className="ml-0 mb-0 mt-0 pt-0 pb-2">
                  {modalConfig.get('TITLE')}
                </Heading>
                {children}
              </div>
            </div>
            <button type="button" data-testid="close-modal" className="btn close" onClick={onClose}>
              <Cross />
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )

  const modalRoot = document.getElementById('modal-root')

  return ReactDOM.createPortal(modalContent, modalRoot)
}

export default Modal
