import React from 'react'

import { Play } from 'components/UI'

const noop = () => {}

const SubmitButton = ({ disabled = false, onClick = noop }) => {
  return (
    <button
      className="btn submit shadow-md mt-6 bg-exp-green font-bold text-white w-64 h-12 rounded-lg disabled:bg-exp-gray-base disabled:cursor-not-allowed disabled:shadow-none transition ease-in-out duration-300"
      data-testid="generate-keys-button"
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <span className="flex flex-row items-center justify-center">
        Generate Keys <Play className="ml-2" />
      </span>
    </button>
  )
}

export default SubmitButton
